// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/train_high_gym_coming_soon_3.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-section {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
}

.hero-section::before {
    content: "";
    background-color: rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,yDAA8E;IAC9E,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gCAAgC;IAChC,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;AACb","sourcesContent":[".hero-section {\n    background-image: url('../../assets/images/train_high_gym_coming_soon_3.webp');\n    height: 300px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff;\n    position: relative;\n}\n\n.hero-section::before {\n    content: \"\";\n    background-color: rgba(0,0,0,.5);\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
